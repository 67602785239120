@import '~antd/dist/antd.css';
@import 'bulma/css/bulma.css';
html,
body {
  height: 100%;
}
.App,
#root,
.AppWrapper {
  min-height: 100%;
  height: 100% !important;
}
.AppWrapper {
  margin-bottom: -6em;
}
.footer {
  padding-bottom: 1em;
}
.navbar-item {
  color: #323232;
  font-weight: 600;
}
.center,
.left,
.right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.left {
  justify-content: flex-start;
}
.right {
  justify-content: flex-end;
}

.main-controls {
}
#clock {
  font-size: 150px;
  font-weight: 800;
  text-align: center;
  color: hsl(171, 100%, 41%);
}
.fill-space {
  height: auto;
}

#clockContainer {
  padding-bottom: 0;
}
.TodoContainer {
  margin-top: 1em;
  margin-left: 10px;
  margin-bottom: 1em;
}
.OpLogContainer {
  margin-top: 1em;
  margin-right: 1em;
  margin-bottom: 1em;
}
.introduce {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0;
}
.introduce article {
  max-width: 800px;
}
/*navbar always flex */
@media only screen and (max-width: 1280px) {
  .navbar {
    display: flex;
  }
  .navbar-item,
  .navbar-link {
    display: flex;
    align-items: center;
  }
  .navbar-end {
    justify-content: flex-end;
    margin-left: auto;
    display: flex;
  }
}
@media only screen and (max-width: 480px) {
  #clock {
    font-size: 120px;
    font-weight: 800;
    text-align: center;
    color: hsl(171, 100%, 41%);
  }

  #clockContainer {
    padding: 1rem;
  }
}

.TodoContainer {
  min-width: 400px;
}
.oplog_list{
  height: 550px;
  width:300px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}